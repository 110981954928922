const config = {
  // Landing Page
  landingcontent: {
    title: "IREG - Senior Living in  Frisco, TX | Watermere at Frisco",
    subtitle:
      "At Watermere at Frisco, we pride ourselves on providing industry-leading service every day.",
    description:
      "Here at the Landing at Watermere Frisco, our mission is to create the perfect blend of comfort, care, and empowered living. We believe that we are simply a small chapter in your loved one’s journey, and are here to help assist in creating a supportive, and caring environment. By partnering with your family, we provide ease and peace of mind, knowing that your loved one is not only cared for, but thriving as well.",
    keywords:
      "Homepage, IREG, Integrated Real Estate Group, Senior Living Frisco, TX, Watermere at Frisco, Senior Care Frisco, TX, Retirement Community Frisco, Assisted Living Frisco, TX, Watermere Landing, Comfort Living Frisco, Empowered Living Frisco, Industry-Leading Senior Service, Supportive Senior Environment, Senior Journey Support, Family Partnering Senior Care, Peace of Mind Senior Care, Thriving Senior Community, Caring Senior Services, Frisco Senior Lifestyle, Frisco Senior Apartments, Senior Wellness Frisco, Personalized Senior Care, Frisco Senior Home, Luxury Senior Living, Active Senior Living Frisco, Independent Living Frisco, TX, Senior Community Frisco, Retirement Homes Frisco, Senior Health Services, Elderly Care Frisco, TX, Senior Housing Frisco, Premier Senior Living Frisco, Senior Assistance Frisco, Senior Support Services, Frisco Senior Care Center, Watermere Senior Services, Compassionate Senior Care, Quality Senior Living, Senior Living Solutions Frisco, Frisco Elder Care, Senior Comfort Care, Watermere Community, Aging with Dignity Frisco, Senior Independence Frisco, Frisco Retirement Living, Holistic Senior Care, Senior Living Facilities, Elderly Support Frisco, Senior Daily Living Frisco, Frisco Senior Programs, Watermere Assisted Living.",
  },

  // Checkout Page
  checkoutcontent: {
    title: "Secure Checkout | Watermere at Frisco - Senior Living Services",
    description:
      "Complete your secure checkout for dining, care, and other senior living services at Watermere at Frisco. We ensure a seamless and convenient process, supporting your loved one's journey to empowered and comfortable living in our thriving community.",
    keywords:
      "IREG, Integrated Real Estate Group, Watermere at Frisco, Senior Living Frisco, Secure Checkout Senior Services, Senior Care Payment Frisco, Senior Dining Checkout, Retirement Community Checkout, Assisted Living Payment, Senior Services Payment Frisco, Comfort Living Payment, Empowered Living Frisco, Senior Wellness Payment, Frisco Senior Community, Retirement Community Payment, Senior Living Services Frisco, Secure Senior Payments, Holistic Senior Care Frisco, Frisco Retirement Living Checkout, Quality Senior Services Payment, Senior Lifestyle Payment Frisco, Senior Support Payment, Watermere Senior Services Checkout, Frisco Senior Wellness Services.",
  },

  // About Us Page
  aboutcontent: {
    title: "IREG - Senior Living in  Frisco, TX | Watermere at Frisco",
    subtitle:
      "At Watermere at Frisco, we pride ourselves on providing industry-leading service every day.",
    description:
      "Here at the Landing at Watermere Frisco, our mission is to create the perfect blend of comfort, care, and empowered living. We believe that we are simply a small chapter in your loved one’s journey, and are here to help assist in creating a supportive, and caring environment. By partnering with your family, we provide ease and peace of mind, knowing that your loved one is not only cared for, but thriving as well.",
    keywords:
      "Senior Living Frisco, TX, Watermere at Frisco, Senior Care Frisco, TX, Retirement Community Frisco, Assisted Living Frisco, TX, Watermere Landing, Comfort Living Frisco, Empowered Living Frisco, Industry-Leading Senior Service, Supportive Senior Environment, Senior Journey Support, Family Partnering Senior Care, Peace of Mind Senior Care, Thriving Senior Community, Caring Senior Services, Frisco Senior Lifestyle, Frisco Senior Apartments, Senior Wellness Frisco, Personalized Senior Care, Frisco Senior Home, Luxury Senior Living, Active Senior Living Frisco, Independent Living Frisco, TX, Senior Community Frisco, Retirement Homes Frisco, Senior Health Services, Elderly Care Frisco, TX, Senior Housing Frisco, Premier Senior Living Frisco, Senior Assistance Frisco, Senior Support Services, Frisco Senior Care Center, Watermere Senior Services, Compassionate Senior Care, Quality Senior Living, Senior Living Solutions Frisco, Frisco Elder Care, Senior Comfort Care, Watermere Community, Aging with Dignity Frisco, Senior Independence Frisco, Frisco Retirement Living, Holistic Senior Care, Senior Living Facilities, Elderly Support Frisco, Senior Daily Living Frisco, Frisco Senior Programs, Watermere Assisted Living.",
    header: "AJ's Keto Factory Why - Our Mission",
    message:
      "We are a team who seeks direction from the Holy Spirit, led by leaders dedicated to using our time, talent, and money to meet the community's physical and spiritual needs to advance God's purpose.",
  },
  aboutbusiness: {
    paragraph1:
      "Experience swift satisfaction at AJ's Keto Factory – your hub for mouthwatering, low-carb meals on the fly! Discover a menu brimming with wheat-free, gluten-free, sugar-free, and soy-free options, carefully prepared daily for a fresh and preservative-free feast. But AJ's is more than just a culinary delight; it's a Kingdom Business, guided by the Holy Spirit, committed to meeting the community's needs. Join us for a taste of purpose at AJ's Keto Factory, where flavor and wellness intertwine in every delectable bite!",
    paragraph2:
      "At AJ's, we're redefining on-the-go dining with our commitment to delicious and health-conscious choices. Our menu features an array of low-carb options that are not only convenient but also crafted with precision – wheat-free, gluten-free, sugar-free, and soy-free. Beyond being your destination for a quick and satisfying meal, we're a Kingdom Business, driven by a higher purpose to serve and uplift our community. Join us at AJ's Keto Factory for a flavorful journey where every bite tells a story of wellness and purpose!",
  },
  littleaboutus: {
    paragraph1:
      "Experience swift satisfaction at AJ's Keto Factory – your hub for mouthwatering, low-carb meals on the fly! Discover a menu brimming with wheat-free, gluten-free, sugar-free, and soy-free options, carefully prepared daily for a fresh and preservative-free feast. But AJ's is more than just a culinary delight; it's a Kingdom Business, guided by the Holy Spirit, committed to meeting the community's needs. Join us for a taste of purpose at AJ's Keto Factory, where flavor and wellness intertwine in every delectable bite!",
    paragraph2:
      "At AJ's, we're redefining on-the-go dining with our commitment to delicious and health-conscious choices. Our menu features an array of low-carb options that are not only convenient but also crafted with precision – wheat-free, gluten-free, sugar-free, and soy-free. Beyond being your destination for a quick and satisfying meal, we're a Kingdom Business, driven by a higher purpose to serve and uplift our community. Join us at AJ's Keto Factory for a flavorful journey where every bite tells a story of wellness and purpose!",
    paragraph3:
      "Savor the essence of guilt-free indulgence at our Keto haven! Delight in a symphony of flavors, from gluten-free and sugar-free to soy-free and wheat-free options. Whether you opt for dine-in luxury or the convenience of curbside pickup and no-contact delivery, our culinary journey awaits. Nestled in St. Charles, Geneva, and South Elgin, our oasis beckons with delectable keto creations made from fresh, natural ingredients. Dive into our gourmet, carb-free offerings, featuring tantalizing keto bakery treats and a medley of satisfying options that redefine the art of healthy dining. Immerse yourself in the essence of the keto lifestyle with our nutrient-rich, high-quality fare, setting the standard for excellence in St. Charles, Geneva, and South Elgin.",
  },

  // Contact Page
  contactcontent: {
    title: "IREG - Senior Living in  Frisco, TX | Watermere at Frisco",
    description:
      "Here at the Landing at Watermere Frisco, our mission is to create the perfect blend of comfort, care, and empowered living. We believe that we are simply a small chapter in your loved one’s journey, and are here to help assist in creating a supportive, and caring environment. By partnering with your family, we provide ease and peace of mind, knowing that your loved one is not only cared for, but thriving as well.",
    keywords:
      "Senior Living Frisco, TX, Watermere at Frisco, Senior Care Frisco, TX, Retirement Community Frisco, Assisted Living Frisco, TX, Watermere Landing, Comfort Living Frisco, Empowered Living Frisco, Industry-Leading Senior Service, Supportive Senior Environment, Senior Journey Support, Family Partnering Senior Care, Peace of Mind Senior Care, Thriving Senior Community, Caring Senior Services, Frisco Senior Lifestyle, Frisco Senior Apartments, Senior Wellness Frisco, Personalized Senior Care, Frisco Senior Home, Luxury Senior Living, Active Senior Living Frisco, Independent Living Frisco, TX, Senior Community Frisco, Retirement Homes Frisco, Senior Health Services, Elderly Care Frisco, TX, Senior Housing Frisco, Premier Senior Living Frisco, Senior Assistance Frisco, Senior Support Services, Frisco Senior Care Center, Watermere Senior Services, Compassionate Senior Care, Quality Senior Living, Senior Living Solutions Frisco, Frisco Elder Care, Senior Comfort Care, Watermere Community, Aging with Dignity Frisco, Senior Independence Frisco, Frisco Retirement Living, Holistic Senior Care, Senior Living Facilities, Elderly Support Frisco, Senior Daily Living Frisco, Frisco Senior Programs, Watermere Assisted Living.",
    message:
      "Welcome to a world where wholesome, flavorful, and satisfying keto dining takes center stage!",
  },

  // Photo Gallery Page
  gallerycontent: {
    title: "IREG - Senior Living in  Frisco, TX | Watermere at Frisco",
    description:
      "Here at the Landing at Watermere Frisco, our mission is to create the perfect blend of comfort, care, and empowered living. We believe that we are simply a small chapter in your loved one’s journey, and are here to help assist in creating a supportive, and caring environment. By partnering with your family, we provide ease and peace of mind, knowing that your loved one is not only cared for, but thriving as well.",
    keywords:
      "Explore, Photo Gallery, Senior Living Frisco, TX, Watermere at Frisco, Senior Care Frisco, TX, Retirement Community Frisco, Assisted Living Frisco, TX, Watermere Landing, Comfort Living Frisco, Empowered Living Frisco, Industry-Leading Senior Service, Supportive Senior Environment, Senior Journey Support, Family Partnering Senior Care, Peace of Mind Senior Care, Thriving Senior Community, Caring Senior Services, Frisco Senior Lifestyle, Frisco Senior Apartments, Senior Wellness Frisco, Personalized Senior Care, Frisco Senior Home, Luxury Senior Living, Active Senior Living Frisco, Independent Living Frisco, TX, Senior Community Frisco, Retirement Homes Frisco, Senior Health Services, Elderly Care Frisco, TX, Senior Housing Frisco, Premier Senior Living Frisco, Senior Assistance Frisco, Senior Support Services, Frisco Senior Care Center, Watermere Senior Services, Compassionate Senior Care, Quality Senior Living, Senior Living Solutions Frisco, Frisco Elder Care, Senior Comfort Care, Watermere Community, Aging with Dignity Frisco, Senior Independence Frisco, Frisco Retirement Living, Holistic Senior Care, Senior Living Facilities, Elderly Support Frisco, Senior Daily Living Frisco, Frisco Senior Programs, Watermere Assisted Living.",
    message:
      "Step into our culinary haven, where ambition meets flavor! Explore our gallery of delicious delights at the ambitious deli and grocery store.",
  },

  // Menu Page
  ordercontent: {
    title:
      "Dining Options at Watermere at Frisco | Senior Living in Frisco, TX",
    description:
      "Discover exceptional dining options at Watermere at Frisco, where we blend nutrition and flavor to provide residents with an empowered, healthy lifestyle. Our menu is designed to meet the dietary needs and preferences of our senior community, ensuring a delightful dining experience every day.",
    keywords:
      "IREG, Integrated Real Estate Group, Senior Living Frisco, TX, Watermere at Frisco, Senior Dining Frisco, Senior Menu Options, Retirement Community Dining, Assisted Living Dining, Senior Nutrition Frisco, Healthy Senior Meals, Senior Wellness Frisco, Senior Care Frisco, Comfort Living Frisco, Empowered Living Frisco, Thriving Senior Community, Senior Dietary Needs, Quality Senior Meals, Frisco Senior Living, Active Senior Living Frisco, Premier Senior Dining, Holistic Senior Care, Frisco Senior Lifestyle, Senior Health and Nutrition, Watermere Community Dining, Frisco Retirement Living, Senior Dining Services, Aging with Dignity Frisco.",
    message:
      "At Watermere at Frisco, we offer a variety of nutritious and delicious dining options, tailored to meet the unique needs of our senior residents. Our menu focuses on promoting wellness, comfort, and community through quality meals that nourish both body and soul.",
  },

  // Eloyalty Page
  eloyaltycontent: {
    title: "IREG - Senior Living in  Frisco, TX | Watermere at Frisco",
    description:
      "Here at the Landing at Watermere Frisco, our mission is to create the perfect blend of comfort, care, and empowered living. We believe that we are simply a small chapter in your loved one’s journey, and are here to help assist in creating a supportive, and caring environment. By partnering with your family, we provide ease and peace of mind, knowing that your loved one is not only cared for, but thriving as well.",
    keywords:
      "Senior Living Frisco, TX, Watermere at Frisco, Senior Care Frisco, TX, Retirement Community Frisco, Assisted Living Frisco, TX, Watermere Landing, Comfort Living Frisco, Empowered Living Frisco, Industry-Leading Senior Service, Supportive Senior Environment, Senior Journey Support, Family Partnering Senior Care, Peace of Mind Senior Care, Thriving Senior Community, Caring Senior Services, Frisco Senior Lifestyle, Frisco Senior Apartments, Senior Wellness Frisco, Personalized Senior Care, Frisco Senior Home, Luxury Senior Living, Active Senior Living Frisco, Independent Living Frisco, TX, Senior Community Frisco, Retirement Homes Frisco, Senior Health Services, Elderly Care Frisco, TX, Senior Housing Frisco, Premier Senior Living Frisco, Senior Assistance Frisco, Senior Support Services, Frisco Senior Care Center, Watermere Senior Services, Compassionate Senior Care, Quality Senior Living, Senior Living Solutions Frisco, Frisco Elder Care, Senior Comfort Care, Watermere Community, Aging with Dignity Frisco, Senior Independence Frisco, Frisco Retirement Living, Holistic Senior Care, Senior Living Facilities, Elderly Support Frisco, Senior Daily Living Frisco, Frisco Senior Programs, Watermere Assisted Living.",
    message:
      "Activate your account now and explore our gourmet, health-conscious menu, packed with natural ingredients and nutritious choices.",
  },

  // SignInSL Page
  signinslcontent: {
    title: "IREG - Senior Living in Frisco, TX | Watermere at Frisco",
    description:
      "At Watermere at Frisco, we provide industry-leading service every day, creating a perfect blend of comfort, care, and empowered living. We partner with families to support their loved ones in a thriving, supportive, and caring environment. Experience personalized, compassionate senior care in a premier retirement community in Frisco, TX.",
    keywords:
      "IREG, Integrated Real Estate Group, Senior Living Frisco, TX, Watermere at Frisco, Senior Care Frisco, TX, Retirement Community Frisco, Assisted Living Frisco, Empowered Living Frisco, Comfort Living Frisco, Senior Wellness Frisco, Independent Living Frisco, Retirement Homes Frisco, Luxury Senior Living, Active Senior Living Frisco, Senior Community Frisco, Elderly Care Frisco, Senior Housing Frisco, Compassionate Senior Care, Aging with Dignity Frisco, Senior Independence Frisco, Holistic Senior Care, Watermere Senior Services, Frisco Retirement Living, Senior Living Facilities Frisco, Quality Senior Living Frisco.",
  },
};

export default config;
